<template>
  <div class="inspired-block">
    <nuxt-img
      class="inspired-block-image"
      :src="inspiredImg || '/splash_screens/icon.png'"
      format="webp"
      :alt="inspiredImgAlt"
    />
    <div class="inspired-block-text">
      <span class="inspired-block-text__title">
        {{ blockData.title || '' }}
      </span>
      <CustomButton
        v-if="inspiredLinkLabel && inspiredUrl"
        layout="blurred"
        :link="inspiredUrl"
        class="inspired-block-text__button"
      >
        {{ $t(inspiredLinkLabel) }}
      </CustomButton>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import CustomButton from '../General/FormElements/CustomButton.vue';
import { useWindow } from '~/composables';

export default defineComponent({
  name: 'Inspired',
  components: { CustomButton },
  props: {
    blockData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();

    const inspiredUrl = computed(() => props.blockData?.link?.url || '/');
    const inspiredLinkLabel = computed(
      () => props.blockData?.link?.label || ''
    );
    const inspiredImg = computed(() =>
      isDesktop.value
        ? props?.blockData?.image?.desktop || ''
        : props?.blockData?.image?.mobile || ''
    );
    const inspiredImgAlt = computed(() => props.blockData?.image?.alt || '');

    return {
      isDesktop,
      inspiredUrl,
      inspiredImg,
      inspiredImgAlt,
      inspiredLinkLabel,
    };
  },
});
</script>

<style lang="scss" scoped>
.inspired-block {
  position: relative;
  z-index: 1;
  &-image {
    display: flex;
    width: 100%;
    object-fit: contain;
  }
  &-text {
    position: absolute;
    top: 31.75%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 17.1875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include for-desktop {
      top: 7.9375rem;
    }
    &__title {
      color: var(--c-white);
      text-transform: uppercase;
      text-align: center;
      @include inspired-mobile-title;
      @include for-desktop {
        @include desktop-h2;
        letter-spacing: var(--letter-spacing-15);
      }
    }
    &__button {
      top: 1.25rem;
      @include for-desktop {
        top: 1.875rem;
      }
    }
  }
}

@include to-mobile-max {
  .inspired-block {
    &-image {
      width: 100%;
      aspect-ratio: 0.93/1;
      object-fit: cover;
    }
  }
}
</style>
