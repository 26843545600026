<template>
  <div class="featured-product-slider__text__buttons">
    <CustomButton
      layout="second"
      @click="updateSliderProducts('women')"
      :class="{ selected: loadedCategory === 'women' }"
    >
      {{ $t('WOMEN') }}
    </CustomButton>
    <CustomButton
      layout="second"
      @click="updateSliderProducts('men')"
      :class="{ selected: loadedCategory === 'men' }"
    >
      {{ $t('MEN') }}
    </CustomButton>
    <CustomButton
      layout="second"
      @click="updateSliderProducts('kids')"
      :class="{ selected: loadedCategory === 'kids' }"
    >
      {{ $t('KIDS') }}
    </CustomButton>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import CustomButton from '~/components/General/FormElements/CustomButton.vue';

export default defineComponent({
  name: 'FeaturedProductButtons',
  components: { CustomButton },
  setup(_, { emit }) {
    const {
      localeProperties: { localeValue },
    } = useI18n();
    const { blocks, loadBlocks } = useContent();
    const loadedCategory = ref('women');

    const updateSliderProducts = async (category) => {
      if (category === loadedCategory.value) return;
      loadedCategory.value = category;
      try {
        await loadBlocks({
          identifiers: [`hp-featured-products-${localeValue}-${category}`],
        });
        const blockContent = await contentGetters.getCmsBlockContent(
          blocks.value
        );
        const blockData =
          blockContent?.[`hp-featured-products-${localeValue}-${category}`]
            ?.data;
        emit('update:text', blockData?.title, blockData?.subtitle);
        const { isCollection } = blockData;
        if (isCollection) {
          const categoryUid = blockData.category_uid;
          if (!categoryUid) return;
          emit('update:category', categoryUid);
          return;
        }
        if (blockData.skus) {
          emit('update:skus', blockData.skus);
        }
      } catch {
        console.warn('Could not get blockConstantName block content');
      }
    };

    return {
      updateSliderProducts,
      loadedCategory,
    };
  },
});
</script>

<style lang="scss" scoped>
.featured-product-slider__text__buttons {
  > .selected {
    cursor: auto;
    pointer-events: none;
    background-color: var(--c-black);
    color: var(--c-white);
  }
}
</style>
