<template>
  <section class="journal-slider" v-if="hasContentToShow && canShowCta">
    <div class="journal-slider__text">
      <div class="journal-slider__text__title">
        {{ journalData.title || 'Divo Magazine' }}
      </div>
      <div class="journal-slider__text__button">
        <CustomButton
          layout="second"
          :link="fixUrlForCurrentLocale(`/${journalData.cta.url || 'journal'}`)"
        >
          {{ journalData.cta.label || 'Magazine' }}
        </CustomButton>
      </div>
    </div>
    <div class="glide journal-slider__glide">
      <NextButton
        v-if="sanitizedSlides.length > 1"
        @changeSlide="changeSlide"
        position="both"
      />
      <div
        class="glide__track journal-slider__glide__track"
        data-glide-el="track"
      >
        <ul class="glide__slides journal-slider__glide__track__slides">
          <li
            class="glide__slide journal-slider__glide__track__slides__slide"
            v-for="(slide, index) in sanitizedSlides"
            :key="index"
          >
            <JournalSlide
              :class="`${
                index == previousSlideIndex
                  ? 'prev'
                  : index == nextSlideIndex
                  ? 'next'
                  : ''
              }${
                isDesktop && index == currentMountedSlideIndex
                  ? ' slide-center'
                  : ''
              }`"
              :slide-data="slide"
              :slide-index="index"
              @changeSlide="changeSlide"
            />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  provide,
} from '@nuxtjs/composition-api';
import Glide, {
  Swipe,
  Breakpoints,
} from '@glidejs/glide/dist/glide.modular.esm';
import JournalSlide from '~/components/Homepage/JournalSlider/JournalSlide.vue';
import NextButton from './NextButton.vue';
import CustomButton from '~/components/General/FormElements/CustomButton.vue';
import { useWindow } from '~/composables';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'JournalSlider',
  components: {
    JournalSlide,
    NextButton,
    CustomButton,
  },
  props: {
    journalData: {
      type: [Array, Object],
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();

    const sanitizedSlides = computed(() => {
      return props.journalData?.slides?.length > 0
        ? props.journalData.slides.filter(
            (slide) =>
              slide?.active !== false &&
              slide?.link &&
              slide?.title &&
              slide?.subtitle &&
              (isDesktop.value ? slide.image?.desktop : slide.image?.mobile)
          )
        : [];
    });

    const glideJournalSlider = ref();
    const currentMountedSlideIndex = ref();
    const currentSlideIndex = computed(
      () => glideJournalSlider.value?.index ?? 0
    );
    const previousSlideIndex = computed(() =>
      currentSlideIndex.value - 1 < 0
        ? sanitizedSlides.length - 1
        : currentSlideIndex.value - 1
    );
    const nextSlideIndex = computed(() =>
      currentSlideIndex.value + 1 > sanitizedSlides.length - 1
        ? 0
        : currentSlideIndex.value + 1
    );
    provide('currentSlideIndex', currentSlideIndex);
    provide('previousSlideIndex', previousSlideIndex);
    provide('nextSlideIndex', nextSlideIndex);

    const canShowCta = computed(
      () =>
        props.journalData.cta &&
        props.journalData.cta.label &&
        props.journalData.cta.url
    );

    const hasContentToShow = computed(
      () =>
        sanitizedSlides.value &&
        Array.isArray(sanitizedSlides.value) &&
        sanitizedSlides.value.length > 0
    );

    const changeSlide = (value) => {
      glideJournalSlider.value.go(value);
    };

    onMounted(() => {
      if (sanitizedSlides.value.length > 0) {
        const journalGlideOptions = {
          type: 'carousel',
          animationDuration: 400,
          gap: 1,
          perView: 5.5,
          peek: 0,
          focusAt: 'center',
          startAt: 2,
          breakpoints: {
            1440: {
              perView: 3.5,
              startAt: 1,
            },
            1024: {
              perView: 1.25,
              startAt: 0,
              focusAt: 0,
            },
          },
        };
        glideJournalSlider.value = new Glide(
          '.journal-slider__glide',
          journalGlideOptions
        )
          .mount({ Swipe, Breakpoints })
          .on(['move'], function () {
            currentMountedSlideIndex.value = null;
          })
          .on(['move.after', 'mount.after'], function () {
            currentMountedSlideIndex.value = currentSlideIndex.value;
          });
      }
    });

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      currentMountedSlideIndex,
      currentSlideIndex,
      previousSlideIndex,
      nextSlideIndex,
      hasContentToShow,
      changeSlide,
      sanitizedSlides,
      canShowCta,
      isDesktop,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss">
.journal-slider {
  width: 100%;
  position: relative;
  z-index: 1;
  @include desktop-boxed;
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.875rem 0;
    @include for-desktop {
      justify-content: space-between;
      flex-direction: row;
      margin: 1.125rem 8.75rem;
    }
    &__title {
      @include for-desktop {
        @include journal-title;
      }
      @include journal-title-mobile;
      padding-bottom: 1.25rem;
    }
  }
  &__glide {
    height: 100%;
    &__track {
      height: 100%;
      &__slides {
        height: 100%;
      }
    }
  }
  @include from-desktop-min {
    overflow: hidden;
    &__glide {
      overflow: visible;
      padding: 5% 0;
      &__track {
        overflow: visible;
        &__slides {
          overflow: visible;
          &__slide {
            overflow: visible;
            .slide-center {
              overflow: visible;
              z-index: 2;
              transform: scale(1.3);
              transition: transform 1s;
            }
          }
        }
      }
    }
  }
}
</style>
