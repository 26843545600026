import { render, staticRenderFns } from "./FeaturedProductSlider.vue?vue&type=template&id=49306943"
import script from "./FeaturedProductSlider.vue?vue&type=script&lang=js"
export * from "./FeaturedProductSlider.vue?vue&type=script&lang=js"
import style0 from "./FeaturedProductSlider.vue?vue&type=style&index=0&id=49306943&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports