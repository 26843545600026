<template>
  <section class="featured-product-slider">
    <div class="featured-product-slider__text">
      <FeaturedProductHeading
        :title="titleAndSubtitle.title"
        :subtitle="titleAndSubtitle.subtitle"
      />
      <FeaturedProductButtons
        @update:skus="updateSkus"
        @update:category="updateCategory"
        @update:text="updateText"
      />
    </div>
    <FeaturedProductGlide
      v-if="hasContentToShow"
      :productsData="productsData"
      :key="sliderKey"
    />
  </section>
</template>

<script>
import { defineComponent, useFetch, ref } from '@nuxtjs/composition-api';

import CustomButton from '~/components/General/FormElements/CustomButton.vue';
import FeaturedProductButtons from './FeaturedProductButtons.vue';
import FeaturedProductHeading from './FeaturedProductHeading.vue';
import FeaturedProductGlide from './FeaturedProductGlide.vue';
import { facetGetters, useFacet } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'FeaturedProductSlider',
  components: {
    CustomButton,
    FeaturedProductButtons,
    FeaturedProductHeading,
    FeaturedProductGlide,
  },
  props: {
    featuredProductsContent: {
      type: Object,
      required: true,
      validator: (value) =>
        !!(
          ((value.data.isCollection && value.data.category_uid) ||
            (!value.data.isCollection && value.data.skus)) &&
          value.data.title &&
          value.data.subtitle
        ),
    },
  },
  setup(props) {
    const { result, search } = useFacet(`featured-product-slider`);
    const products = ref([]);
    const productsData = ref([]);
    const hasContentToShow = ref(false);
    const sliderKey = ref(`featured-product-slider`);
    const titleAndSubtitle = ref({
      title: props.featuredProductsContent?.data?.title,
      subtitle: props.featuredProductsContent?.data?.subtitle,
    });

    const updateText = (newTitle, newSubtitle) => {
      titleAndSubtitle.value = {
        title: newTitle,
        subtitle: newSubtitle,
      };
    };

    const updateCategory = async (categoryUid) => {
      try {
        await search({
          categoryId: `grn:product-list:product-list::${categoryUid}`,
          customQuery: {
            products: 'productListCustom',
          },
        });
        products.value = facetGetters.getProducts(result.value);
        productsData.value = [];
        products.value.forEach((product) => {
          productsData.value.push({
            thumbnail: product.thumbnail.url,
            productBrand: product.brand_attribute.label,
            url: product.url_key,
          });
        });
      } catch (error) {
        console.error(error);
      } finally {
        hasContentToShow.value =
          productsData.value?.length > 0 && products.value?.length > 0;
        sliderKey.value = `featured-product-slider-${categoryUid}`;
      }
    };

    const updateSkus = async (skus) => {
      try {
        await search({
          filters: {
            sku: skus.split(','),
          },
          customQuery: {
            products: 'productListCustom',
          },
        });
        productsData.value = [];
        facetGetters.getProducts(result.value).forEach((product) => {
          productsData.value.push({
            thumbnail: product.thumbnail.url,
            productBrand: product.brand_attribute.label,
            url: product.url_key,
          });
        });
      } catch (error) {
        console.error(error);
      } finally {
        hasContentToShow.value =
          productsData.value?.length > 0 && products.value?.length > 0;
        sliderKey.value = `featured-product-slider-${skus.replace(/,/g, '-')}`;
      }
    };

    useFetch(async () => {
      const blockData = props.featuredProductsContent.data;
      await (blockData.isCollection
        ? updateCategory(blockData.category_uid)
        : updateSkus(blockData.skus));
    });

    return {
      hasContentToShow,
      productsData,
      products,
      updateSkus,
      updateCategory,
      updateText,
      sliderKey,
      titleAndSubtitle,
    };
  },
});
</script>

<style lang="scss">
.featured-product-slider {
  .custom-button:hover,
  .custom-button__disabled {
    background: #000 !important;
  }
  width: 100%;
  position: relative;
  z-index: 1;
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1.875rem 0;
    @include for-desktop {
      justify-content: space-between;
      flex-direction: column;
      margin: 1.125rem 8.75rem;
    }
    &__title {
      @include for-desktop {
        @include journal-title;
      }
      @include journal-title-mobile;
      padding-bottom: 0.625rem;
    }
    &__subtitle {
      @include paragraph-regular;
      letter-spacing: var(--letter-spacing-8);
      text-transform: uppercase;
      padding: 0 1.25rem 1.25rem;
    }
    &__buttons {
      display: flex;
      .custom-button {
        --button-width: unset;
      }
    }
  }
  &__glide {
    height: 100%;
    &__track {
      height: 100%;
      &__slides {
        height: 100%;
      }
    }
  }
}
</style>
