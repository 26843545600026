<template>
  <div v-if="seoText" class="seo-text">
    {{ seoText }}
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SeoText',
  props: {
    seoText: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
.seo-text {
  position: relative;
  z-index: 1;
  @include paragraph-extra-small;
  padding: 5rem 1.25rem;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.0406rem;
  color: var(--c-dark-grey);
  @include for-desktop {
    padding: 5.625rem 8.75rem 6.25rem;
  }
}
</style>
